// src/components/SolutionPage.js
import React from "react";
import { useParams } from "react-router-dom";

import code_secJson from "./Solution_Data/Code-Security.json";
import team_manJson from "./Solution_Data/Team-Management.json";
import access_contJson from "./Solution_Data/Access-Control.json";
import { useEffect, useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";

const solutionDataMap = {
  "code-security": code_secJson,
  "team-management": team_manJson,
  "access-control": access_contJson,
};

const SolutionPage = () => {
  const { solutionId } = useParams();
  const [solutionData, setSolutionData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    const solutionData = solutionDataMap[solutionId];
    console.log("Loaded project data:", solutionData); // Check what data is being loaded
    setSolutionData(solutionData);
  }, [solutionId]);

  if (!solutionData || Object.keys(solutionData).length === 0) {
    return <div>Loading or no data available...</div>;
  }

  
  return (
    <div className="py-16 bg-gray-50">
      <div className="mx-auto mx-8 lg:px-8 xl:ml-12 py-28">
        {/* Heading */}
        <h1 className="text-[44px] font-bold font-nunito mb-4">
          Service Details
        </h1>

        {/* Breadcrumb */}
        <nav className="text-[16px] font-nunito font-semibold mb-4">
          <a href="/company-website-reactjs" className="text-gray-500 hover:text-ff5d22">
            Home
          </a>{" "}
          <i className="fas fa-chevron-right text-ff5d22 mx-2"></i>{" "}
          <span className="text-gray-900">Service Details</span>
        </nav>

        {/* Main Content */}
        <div className="flex flex-wrap -mx-4 mt-20">
          {/* Left Column */}
          <div className="w-full lg:w-2/3 px-4 mb-8 lg:mb-0">
            <img
              className="w-full rounded-lg mb-8"
              src={solutionData.image}
              alt="Service"
            />
            <h2 className="text-[16px] font-nunito text-ff5d22 font-semibold mb-2">
              {solutionData.services[0]}
            </h2>
            <h3 className="text-[26px] font-bold font-nunito mb-4 mt-4">
              About this Services
            </h3>
            <p className="text-gray-500 mb-8 font-nunito text-[16px]">
              {solutionData.mainDescription}
            </p>
            {/* Important Facts Section */}
            <div className="flex flex-col lg:flex-row mb-8">
              <img
                className="w-full lg:w-1/2 rounded-lg mb-4 lg:mb-0 lg:mr-12"
                src={solutionData.additionalImage}
                alt="Important Facts"
              />
              <div>
                <h3 className="text-[24px] font-nunito font-semibold mb-4">
                  Important Facts
                </h3>
                <ul className="list-disc list-inside text-gray-500 mb-8">
                  {solutionData.importantFacts.map((fact, index) => (
                    <li
                      key={index}
                      className="marker:text-ff5d22 marker:text-3xl font-nunito pb-1"
                    >
                      {fact}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Paragraph after Important Facts */}
            <p className="text-gray-500 mb-12 font-nunito text-[16px]">
              {solutionData.paragraphAfterFacts}
            </p>
            {/* Application Areas Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-12">
              {solutionData.applicationAreas.map((area, index) => (
                <div
                  key={index}
                  className="bg-gray-100 text-gray-700 py-3 px-5 flex items-center rounded-lg transition-colors duration-300 hover:bg-ff5d22 hover:text-white"
                >
                  <i className={`${area.icon} mr-3 text-xl`}></i>
                  <span className="text-[16px] font-nunito font-semibold">
                    {area.name}
                  </span>
                </div>
              ))}
            </div>
            {/* Technologies Section */}
            <h3 className="text-[24px] font-nunito font-semibold mt-8 mb-8">
              Technologies That We Use
            </h3>
            <ul className="list-disc list-inside text-gray-500 mb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4">
              {solutionData.technologies.map((tech, index) => (
                <li key={index} className="marker:text-ff5d22 marker:text-3xl mb-2">
                  {tech}
                </li>
              ))}
            </ul>
            {/* img tag remain to get from json */}
            <img
              className="w-full lg:w-4/5 rounded-lg"
              src="https://tarn-react.envytheme.com/img/services/charts.jpg"
              alt="Technology"
            />{" "}
            {/* Replace with actual image path */}
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-1/3 px-4">
            {/* Services Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
              {solutionData.services.map((service, index) => (
                <div key={index} className={`flex justify-between items-center p-4 mb-2 rounded cursor-pointer transition-colors duration-300 ${
                    index === 0 ? 'bg-ff5d22 text-white' : 'bg-white text-gray-900 hover:bg-ff5d22 hover:text-white'
                  }`}>
                  <span className={`font-bold font-nunito text-[17px] ${index === 0 ? '' : 'hover:text-white'}`}>{service}</span>
                  <i className={`fas fa-chevron-right ${index === 0 ? '' : 'hover:text-white'}`}></i>
                </div>
              ))}
            </div>

            {/* Brochures Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
              <h3 className="font-bold font-nunito text-[24px] mb-4">
                Brochures
              </h3>
              <ul className="list-none text-gray-500 divide-y divide-gray-200">
                <li className="flex justify-between items-center py-2 hover:text-ff5d22 transition-colors duration-300">
                  <a
                    href="/company-website-reactjs"
                    className="flex-grow font-nunito text-[16px] hover:text-ff5d22 mt-2 mb-2"
                  >
                    PDF Download
                  </a>
                  <i className="fas fa-file-alt text-gray-500 hover:text-ff5d22 mt-3"></i>
                </li>
                <li className="flex justify-between items-center py-2 hover:text-ff5d22 transition-colors duration-300">
                  <a
                    href="/company-website-reactjs"
                    className="flex-grow font-nunito text-[16px] hover:text-ff5d22 mt-2"
                  >
                    Service Details.txt
                  </a>
                  <i className="fas fa-file-alt text-gray-500 hover:text-ff5d22 mt-2"></i>
                </li>
              </ul>
            </div>

            {/* Contact Info Section */}
            <div className="bg-white shadow-lg rounded-lg p-6">
              <h3 className="font-nunito text-[24px] font-bold mb-4">
                Contact Info
              </h3>
              <div className="flex items-center mb-4">
                <i className="fas fa-phone-alt text-ff5d22 mr-4"></i>
                <div>
                  <p className="font-semibold font-nunito text-gray-900 text-[16px] mt-5">
                    Phone:
                  </p>
                  <p className="text-gray-500 font-nunito">{solutionData.contactInfo.phone}</p>
                </div>
              </div>
              <div className="flex items-center mb-4">
                <i className="fas fa-map-marker-alt text-ff5d22 mr-4"></i>
                <div>
                  <p className="font-semibold font-nunito text-gray-900 text-[16px] mt-5">
                    Location:
                  </p>
                  <p className="text-gray-500 font-nunito">{solutionData.contactInfo.location}</p>
                </div>
              </div>
              <div className="flex items-center">
                <i className="fas fa-envelope text-ff5d22 mr-4"></i>
                <div>
                  <p className="font-semibold font-nunito text-gray-900 text-[16px] mt-5">
                    Email:
                  </p>
                  <p className="text-gray-500 font-nunito">{solutionData.contactInfo.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionPage;
