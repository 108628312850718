import React, { useEffect, useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import projectsData from "./Project_Data/ProjectsData.json"; // Import the JSON data
import { useNavigate } from "react-router-dom";
import AOS from "aos";
// import "aos/dist/aos.css"; // Import AOS CSS for animations

import CarClick from '../img/Carclickbanner.png';
import CONXTS from '../img/Conxtsbanner.png';
import HiiveApp from '../img/Hiivebanner.png';
import CONXTSMocks from '../img/Conxtsbanner.png';
import SloyedDesigns from '../img/Sloyedbanner.png';
import Digidex from '../img/Digidexbanner.png';
import ExhaleRehab from '../img/Exhalebanner.png';
import RUNovel from '../img/noval.jpeg'; // Double-check the filename and path
import EEPLMobile from '../img/Eeplbanner.png';
import TeachersOnlyTutoring from '../img/Totbanner.png';
import LaurasCleaningApp from '../img/Laurabanner.png';
import MedRecords from '../img/Medrecordsbanner.png';
import SymphonyMusic from '../img/Symphonybanner.png';
import Gigl from '../img/Giglbanner.png';
import BgImage from '../img/pblue.png'; // Import the new background image

const imageMap = {
  "CarClick": CarClick,
  "CONXTS": CONXTS,
  "Hiive App": HiiveApp,
  "CONXTS Mocks": CONXTSMocks,
  "Sloyed Designs": SloyedDesigns,
  "Digidex": Digidex,
  "Exhale Rehab": ExhaleRehab,
  "RU Noval": RUNovel, // Ensure the title matches exactly with the project data
  "EEPL Mobile": EEPLMobile,
  "Teachers Only Tutoring": TeachersOnlyTutoring,
  "Laura's Cleaning App": LaurasCleaningApp,
  "MedRecords": MedRecords,
  "Symphony Music": SymphonyMusic,
  "Gigl": Gigl
};

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All");
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
    setProjects(projectsData);
    setFilteredProjects(projectsData);
  }, []);

  const handleDivClick = (projectId) => {
    navigate(`/CaseStudyPage/${projectId}`);
  };

  const filterProjects = (category) => {
    setActiveCategory(category);
    if (category === "All") {
      setFilteredProjects(projects);
    } else {
      const filtered = projects.filter((project) => project.type === category);
      setFilteredProjects(filtered);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(filteredProjects.length, 3),
    slidesToScroll: 1,
    centerMode: filteredProjects.length < 2,
    centerPadding:
      filteredProjects.length === 1
        ? "33.333%"
        : filteredProjects.length === 2
        ? "33.333%"
        : "0",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(filteredProjects.length, 2),
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          centerMode: filteredProjects.length < 2,
          centerPadding: filteredProjects.length === 1 ? "25%" : "0",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
    ],
  };

  return (
    <div
      id="Projects"
      className="pt-32 pb-16 text-center"
      style={{
        backgroundImage: `url(${BgImage})`, // Use the locally stored background image
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
      }}
    >
      <div className="container mx-auto px-4">
        <div className="mb-12">
          <h2 className="text-[#458BB5]  text-[15px] font-nunito font-semibold flex items-center justify-center">
            <img
              src="https://tarn-react.envytheme.com/img/star-icon.png"
              alt="Icon"
              className="w-8 h-8 mr-2 rotate-slow"
            />{" "}
            RECENT PROJECTS
          </h2>
          <h3 className="text-[36px] font-nunito font-bold text-white mt-2">
            Check Some Of Our Recent Work
          </h3>
          <p className="text-gray-400 mt-4 max-w-3xl mx-auto text-[16px] font-nunito">
            Explore our showcase of recent projects, each a testament to our
            creativity and expertise. From innovative solutions to seamless
            execution, we deliver results that exceed expectations.
          </p>
          <div className="flex flex-wrap justify-center mt-8 space-x-4 mx-4 sm:mx-2 md:mx-8 lg:mx-0">
            <button
              className={`mt-4 px-4 py-2 rounded-lg text-sm font-semibold ${
                activeCategory === "All"
                  ? "bg-[#458BB5]  text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => filterProjects("All")}
            >
              All
            </button>
            <button
              className={`mt-4 px-4 py-2 rounded-lg text-sm font-semibold ${
                activeCategory === "Web Project"
                  ? "bg-[#458BB5]  text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => filterProjects("Web Project")}
            >
              Web Project
            </button>
            <button
              className={`mt-4 px-4 py-2 rounded-lg text-sm font-semibold ${
                activeCategory === "Mobile App Development"
                  ? "bg-[#458BB5]  text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => filterProjects("Mobile App Development")}
            >
              Mobile App Development
            </button>
            <button
              className={`mt-4 px-4 py-2 rounded-lg text-sm font-semibold ${
                activeCategory === "UI/UX Development"
                  ? "bg-[#458BB5]  text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => filterProjects("UI/UX Development")}
            >
              UI/UX Development
            </button>
          </div>
        </div>
        <div className="max-w-screen-xl mx-auto px-8">
          <Slider {...settings}>
            {filteredProjects.map((project, index) => (
              <div
                key={index}
                className="p-4 mx-auto max-w-[370px]"
                // data-aos="zoom-in"
                // data-aos-duration="1000"
                // data-aos-delay={`${100 * index}`}
                onClick={() => handleDivClick(project.id)} // Navigate using project ID
              >
                <div className="bg-white max-w-full md,lg,xl:max-w-[370px] rounded-lg shadow-lg overflow-hidden relative group transition duration-500 ease-in-out hover:scale-105">
                  <img
                    className="w-full h-120 object-cover group-hover:opacity-50 transition-opacity duration-500"
                    src={imageMap[project.title]} // Use imageMap to dynamically set the image based on the project title
                    alt={project.title}
                  />
                  <div className="absolute inset-0 flex items-center justify-center text-[#458BB5] opacity-0 group-hover:opacity-100 transition-opacity duration-500 ">
                    <i className="fas fa-plus text-4xl"></i>
                  </div>
                  <div className="p-6">
                    <h4 className="lg:text-[16px] xl:text-[18px] font-nunito font-semibold mb-2 text-gray-900 group-hover:text-[#458BB5]  transition-colors duration-500 mx-auto">
                      {project.title}
                    </h4>
                    <p className="text-[16px] font-nunito text-[#458BB5] ">
                      {project.type}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Projects;
