// src/components/BlogPage.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import blogData from "./Solution_Data/Blog.json"; // Import the Blog data
import "@fortawesome/fontawesome-free/css/all.min.css";

const BlogPage = () => {
  const [blogDataState, setBlogDataState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setBlogDataState(blogData); // Assuming blogData is a single JSON object, not an array
    console.log("Loaded blog data:", blogData);
  }, []);

  if (!blogDataState || Object.keys(blogDataState).length === 0) {
    return <div>Loading or no data available...</div>;
  }

  return (
    <div className="py-16 bg-gray-50">
      <div className="mx-auto mx-8 lg:px-8 xl:ml-12 py-28">
        {/* Heading */}
        <h1 className="text-[44px] font-bold font-nunito mb-4">
          Blog Page
        </h1>

        {/* Breadcrumb */}
        <nav className="text-[16px] font-nunito font-semibold mb-4">
          <a href="/" className="text-gray-500 hover:text-[#458BB5]">
            Home
          </a>{" "}
          <i className="fas fa-chevron-right text-[#458BB5] mx-2"></i>{" "}
          <span className="text-gray-900">Blog Page</span>
        </nav>

        {/* Main Content */}
        <div className="flex flex-wrap -mx-4 mt-20">
          {/* Left Column */}
          <div className="w-full lg:w-2/3 px-4 mb-8 lg:mb-0">
            <img
              className="w-full rounded-lg mb-8"
              src={blogData.image}
              alt="Service"
            />
            <h2 className="text-[16px] font-nunito [#458BB5] font-semibold mb-2">
              {blogData.title}
            </h2>
            <p className="text-gray-500 mb-12 font-nunito text-[16px]">
              {blogData.description}
            </p>
            <h3 className="text-[26px] font-bold font-nunito mb-4 mt-4">
              About this Blog
            </h3>
            <p className="text-gray-500 mb-8 font-nunito text-[16px]">
              {blogData.mainDescription}
            </p>
            {/* Important Facts Section */}
            <div className="flex flex-col lg:flex-row mb-8">
              <img
                className="w-full lg:w-1/2 rounded-lg mb-4 lg:mb-0 lg:mr-12"
                src={blogData.additionalImage}
                alt="Important Facts"
              />
              <div>
                <h3 className="text-[24px] font-nunito font-semibold mb-4">
                  Important Facts
                </h3>
                <ul className="list-disc list-inside text-gray-500 mb-8">
                  {blogData.importantFacts.map((fact, index) => (
                    <li
                      key={index}
                      className="marker:[#458BB5] marker:text-3xl font-nunito pb-1"
                    >
                      {fact}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <p className="text-gray-500 mb-12 font-nunito text-[16px]">
              {blogData.longDescription}
            </p>
            {/* Technologies Section */}
            <h3 className="text-[24px] font-nunito font-semibold mt-8 mb-8">
              Technologies That We Use
            </h3>
            <ul className="list-disc list-inside text-gray-500 mb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4">
              {blogData.technologies.map((tech, index) => (
                <li key={index} className="marker:[#458BB5] marker:text-3xl mb-2">
                  {tech}
                </li>
              ))}
            </ul>
            <img
              className="w-full lg:w-4/5 rounded-lg"
              src={blogData.lastImage}
              alt="Technology"
            />
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-1/3 px-4">
            {/* Services Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
              {blogData.services.map((service, index) => (
                <div key={index} className={`flex justify-between items-center p-4 mb-2 rounded cursor-pointer transition-colors duration-300 ${
                    index === 0 ? 'bg-[#458BB5] text-white' : 'bg-white text-gray-900 hover:bg-[#458BB5]'
                  }`}>
                  <span className={`font-bold font-nunito text-[17px] ${index === 0 ? '' : 'hover:text-white'}`}>{service}</span>
                </div>
              ))}
            </div>

            {/* Brochures Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
              <h3 className="font-bold font-nunito text-[24px] mb-4">
                Brochures
              </h3>
              <ul className="list-none text-gray-500 divide-y divide-gray-200">
                <li className="flex justify-between items-center py-2 hover:text-[#458BB5] transition-colors duration-300">
                  <a
                    href="/"
                    className="flex-grow font-nunito text-[16px] hover:text-[#458BB5] mt-2 mb-2"
                  >
                    PDF Download
                  </a>
                  <i className="fas fa-file-alt text-gray-500 hover:text-[#458BB5] mt-3"></i>
                </li>
                <li className="flex justify-between items-center py-2 hover:text-[#458BB5] transition-colors duration-300">
                  <a
                    href="/"
                    className="flex-grow font-nunito text-[16px] hover:text-[#458BB5] mt-2"
                  >
                    Service Details.txt
                  </a>
                  <i className="fas fa-file-alt text-gray-500 hover:text-[#458BB5] mt-2"></i>
                </li>
              </ul>
            </div>

            {/* Contact Info Section */}
            <div className="bg-white shadow-lg rounded-lg p-6">
              <h3 className="font-nunito text-[24px] font-bold mb-4">
                Contact Info
              </h3>
              <div className="flex items-center mb-4">
                <i className="fas fa-phone-alt text-[#458BB5] mr-4"></i>
                <div>
                  <p className="font-semibold font-nunito text-gray-900 text-[16px] mt-5">
                    Phone:
                  </p>
                  <p className="text-gray-500 font-nunito">+447465655152</p>
                </div>
              </div>
              <div className="flex items-center mb-4">
                <i className="fas fa-map-marker-alt text-[#458BB5] mr-4"></i>
                <div>
                  <p className="font-semibold font-nunito text-gray-900 text-[16px] mt-5">
                    Location:
                  </p>
                  <p className="text-gray-500 font-nunito">
                    2/3 4, Greenbank Street Glasgow, Scotland G73 1JU
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <i className="fas fa-envelope text-[#458BB5] mr-4"></i>
                <div>
                  <p className="font-semibold font-nunito text-gray-900 text-[16px] mt-5">
                    Email:
                  </p>
                  <p className="text-gray-500 font-nunito">info@dmzdevzone.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
