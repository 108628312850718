import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import ContactUsImg from "../img/ContactUs.png";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    Name: "",
    from_name: "",
    Email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    if (!formData.Name) {
      tempErrors["Name"] = "Name is required";
      isValid = false;
    }
    if (!formData.Email) {
      tempErrors["Email"] = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
      tempErrors["Email"] = "Email is not valid";
      isValid = false;
    }
    if (!formData.phone) {
      tempErrors["phone"] = "Phone number is required";
      isValid = false;
    }
    if (!formData.message) {
      tempErrors["message"] = "Message is required";
      isValid = false;
    }
    if (!captchaVerified) {
      tempErrors["captcha"] = "Please verify that you are not a robot.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setCaptchaVerified(!!value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      emailjs
        .send(
          "service_13ug08r",
          "template_e9bzuu4",
          formData,
          "N6sSmVMD-XWcKkL7O"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setSuccessMessage("Message sent successfully!");
            setErrorMessage("");
            setFormData({
              Name: "",
              from_name: "",
              Email: "",
              phone: "",
              message: "",
            });
          },
          (error) => {
            console.log("FAILED...", error);
            setErrorMessage("Failed to send the message. Please try again.");
          }
        );
    }
  };

  return (
    <div className="contact-us-container bg-gray-50">
      {/* Banner Section */}
      <div className="ml-16 py-32">
        <div className="page-title-content flex justify-between items-center">
          <div>
            <strong className="text-bold text-5xl pt-6 pl-4">Contacts</strong>
            <nav className="text-[16px] font-nunito font-semibold mb-4 ml-6 pt-4">
              <a
                href="/company-website-reactjs"
                className="text-gray-500 hover:text-[#458BB5]"
              >
                Home
              </a>{" "}
              <i className="fas fa-chevron-right text-[#458BB5] mx-2"></i>{" "}
              <span className="text-gray-900">Service Details</span>
            </nav>
          </div>
          {/* <div className="shape-img2 pr-12">
            <img
              alt="shape2"
              loading="lazy"
              width="202"
              height="202"
              decoding="async"
              src="https://tarn-react.envytheme.com/img/shape/shape2.png"
              style={{ opacity: 0.3 }}
              className="opacity-30 animate-spin-vertical"
            />
          </div> */}
        </div>
      </div>

      {/* Contact Info Section */}
      <div className="contact-info-area pt-100 pb-70 bg-gray-50 mb-12">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center">
            {/* Address */}
            <div className="contact-info-box w-full md:w-1/2 lg:w-1/3 p-4 bg-gray-50  flex items-center">
              <div className="icon bg-gray-100 p-4 text-[#458BB5] text-5xl mr-4">
                <FontAwesomeIcon icon={faLocationDot} />
              </div>
              <div>
                <h3 className="text-xl font-semibold">Our Address</h3>
                <p className="text-gray-600">
                  2/3 4, Greenbank Street Glasgow, Scotland G73 1JU
                </p>
              </div>
            </div>

            {/* Phone */}
            <div className="contact-info-box w-full md:w-1/2 lg:w-1/3 p-4 bg-gray-50  flex items-center">
              <div className="icon bg-gray-100 p-4 text-[#458BB5] text-4xl mr-4">
                <FontAwesomeIcon icon={faPhoneVolume} />
              </div>
              <div>
                <h3 className="text-xl font-semibold">Contact</h3>
                <p className="text-gray-600">
                  Mobile:{" "}
                  <a
                    href="tel:+447465655152"
                    className="text-[#458BB5] hover:underline"
                  >
                    (+44) - 74656 - 55152
                  </a>
                  <br />
                  E-mail:{" "}
                  <a
                    href="mailto:info@dmzdevhub.com"
                    className="text-[#458BB5] hover:underline"
                  >
                    info@dmzdevhub.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Form and Image Section */}
      <div className="contact-area pb-100">
        <div className="container">
          <div className="section-title container mx-auto text-center pb-12">
            <div className="mb-12 flex items-center justify-center">
              <img
                src="https://tarn-react.envytheme.com/img/star-icon.png"
                alt="Star Icon"
                className="w-8 h-8 mr-2 rotate-slow"
                // data-aos="fade-up"
                // data-aos-duration="1200"
                // data-aos-delay="300"
              />
              <h2
                className="text-[#458BB5] text-[15px] font-semibold"
                // data-aos="fade-up"
                // data-aos-duration="1200"
                // data-aos-delay="300"
              >
                GET IN TOUCH
              </h2>
            </div>
            <h3
              className="text-5xl font-nunito font-bold max-w-2xl mx-auto"
              // data-aos="fade-up"
              // data-aos-duration="1200"
              // data-aos-delay="300"
            >
              Ready to Get Started?
            </h3>
            <p
              className="mt-4 max-w-3xl mx-auto"
              // data-aos="fade-up"
              // data-aos-duration="1200"
              // data-aos-delay="300"
            >
              Ready to take the next step? Fill out the form below to get in touch with us.
            </p>
          </div>
          <div className="flex flex-wrap justify-center items-center">
            {/* Image */}
            <div className="w-full lg:w-1/2 p-4">
              <img
                src={ContactUsImg}
                alt="contact"
                className="max-w-full h-auto"
              />
            </div>
            {/* Form */}
            <div className="w-full lg:w-1/2 p-4">
              <div className="form bg-white shadow-xl px-8 py-6">
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="Name"
                    value={formData.Name}
                    onChange={handleChange}
                    className="text-black-700 bg-gray-100 form-input hover:border-[#458BB5] block w-full h-14 p-2 my-6 border border-solid border-gray-200"
                    placeholder="Name"
                  />
                  {errors.Name && <p className="text-red-500">{errors.Name}</p>}
                  <input
                    type="email"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    className="text-black-700 bg-gray-100 form-input hover:border-[#458BB5] block w-full h-14 p-2 my-6 border border-solid border-gray-200"
                    placeholder="Email"
                  />
                  {errors.Email && (
                    <p className="text-red-500">{errors.Email}</p>
                  )}
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="text-black-700 bg-gray-100 form-input hover:border-[#458BB5] block w-full h-14 p-2 my-6 border border-solid border-gray-200"
                    placeholder="Phone"
                  />
                  {errors.phone && (
                    <p className="text-red-500">{errors.phone}</p>
                  )}
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="text-black-700 bg-gray-100 form-input hover:border-[#458BB5] block w-full h-24 p-2 my-6 border border-solid border-gray-200"
                    placeholder="Message"
                  />
                  {errors.message && (
                    <p className="text-red-500">{errors.message}</p>
                  )}

                  <ReCAPTCHA
                    sitekey="6Lfe2UUqAAAAAG1PR6HLd8fz8AsN74z74krDW5vF"
                    onChange={handleCaptchaChange}
                  />
                  {errors.captcha && (
                    <p className="text-red-500">{errors.captcha}</p>
                  )}

                  <button
                    type="submit"
                    className="bg-[#458BB5] py-4 px-6 rounded-md w-full mt-6"
                  >
                    Send Message
                  </button>

                  {successMessage && (
                    <p className="text-green-500 mt-4">{successMessage}</p>
                  )}
                  {errorMessage && (
                    <p className="text-red-500 mt-4">{errorMessage}</p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
