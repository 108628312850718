
import React, { useEffect, useState } from "react";
const PrivacyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); 
  return (
    <div className="py-16 bg-gray-50">
      <div className="mx-auto mx-8 px-8 xl:ml-12 py-18">
        {/* Heading */}
        <div>
        <div className="page-title-content flex center justify-between items-center pt-16">
          <div className="ml-4">
            <strong className="text-bold text-5xl pt-6">
            Privacy Policy
            </strong>
            <nav className="text-[16px] font-nunito font-semibold ml-4 pt-4 mb-4">
              <a href="/" className="text-gray-500 hover:text-ff5d22">
                Home
              </a>{" "}
              <i className="fas fa-chevron-right text-ff5d22 mx-2"></i>{" "}
              <span className="text-gray-900">Privacy Policy</span>
            </nav>
          </div>
          <div className="shape-img2 pr-12">
            <img
              alt="shape2"
              loading="lazy"
              width="202"
              height="202"
              decoding="async"
              src="https://tarn-react.envytheme.com/img/shape/shape2.png"
              style={{ opacity: 0.3 }}
              className="opacity-30 animate-spin-vertical"
            />
          </div>
        </div>
      </div>

        <div className="flex flex-wrap mt-12">
          <div className="w-full lg:w-2/3">
            <div className="privacy-policy-content bg-white p-8 shadow-lg rounded-lg">
              <img
                src="https://tarn-react.envytheme.com/img/privacy-policy.jpg"
                alt="Privacy Policy"
                width="750"
                height="650"
                className="w-full rounded-lg mb-8"
              />
              <p className="italic mb-4">
                This Privacy Policy was last updated on January 1, 2023.
              </p>
              <h3 className="text-xl font-bold mb-3">1. What Data We Get</h3>
              <blockquote className="bg-[rgb(230,242,245)] p-4 italic border-l-4 border-gray-500 pl-4 mb-4">
                <p>
                  We collect certain data from you directly, like information
                  you enter yourself, data about your participation in courses,
                  and data from third-party platforms you connect with
                  WideCademy. We also collect some data automatically, like
                  information about your device and what parts of our Services
                  you interact with or spend time using.
                </p>
              </blockquote>
              <h3 className="text-xl font-bold mb-3">
                2. Data You Provide to Us
              </h3>
              <p className="mb-4">
                We may collect different data from or about you depending on how
                you use the Services. Below are some examples to help you better
                understand the data we collect.
              </p>
              <h3 className="text-xl font-bold mb-3">
                3. How We Get Data About You
              </h3>
              <p className="mb-4">
                We use tools like cookies, web beacons, analytics services, and
                advertising providers to gather the data listed above.
              </p>
              <h3 className="text-xl font-bold mb-3">
                4. What We Use Your Data For
              </h3>
              <ol className="list-decimal list-inside mb-4">
                <li>Responding to your questions and concerns;</li>
                <li>
                  Sending you administrative messages and information,
                  notifications about changes to our Service, and updates to our
                  agreements;
                </li>
                <li>
                  Sending push notifications to your wireless device to provide
                  updates and other relevant messages (which you can manage from
                  the “options” or “settings” page of the mobile app);
                </li>
              </ol>
              <h3 className="text-xl font-bold mb-3">
                5. Your Choices About the Use of Your Data
              </h3>
              <p>
                You can choose not to provide certain data to us, but you may
                not be able to use certain features of the Services.
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>
                  To stop receiving promotional communications from us, you can
                  opt out by using the unsubscribe mechanism in the promotional
                  communication you receive.
                </li>
                <li>
                  The browser or device you use may allow you to control cookies
                  and other types of local data storage.
                </li>
              </ul>
              <h3 className="text-xl font-bold mb-3">
                6. Our Policy Concerning Children
              </h3>
              <p className="mb-4">
                We recognize the privacy interests of children and encourage
                parents and guardians to take an active role in their children’s
                online activities and interests.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
