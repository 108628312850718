import React from "react";
import { useNavigate } from 'react-router-dom';
import GetStartedImg from "../img/Ready_to_get_started.png";

function GetStarted() {
  const navigate = useNavigate();
  const handleContactUs = () => {
    navigate('/ContactUs');
  };

  return (
    <div className="min-h-screen bg-cover bg-center flex items-center" style={{
        backgroundImage: "url('https://tarn-react.envytheme.com/img/shape/circle-shape1.png')",
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '350px 386px'
      }}>
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row-reverse items-center justify-between">
          <div className="lg:w-1/2 w-full lg:pl-12 mb-8 lg:mb-0 lg:pr-12">
            <div className="text-left text-black pl-4">
              <h1
                className="text-3xl text-black sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4"
                // data-aos="fade-up"
                // data-aos-duration="800"
                // data-aos-delay="100"
              >
                We Like to Start Your Project With Us
              </h1>
              <p
                // className="text-base sm:text-lg mb-6"
                // data-aos="fade-up"
                // data-aos-duration="800"
                // data-aos-delay="200"
              >
                Inspired to begin your journey with us? Share your vision, and let's embark on a collaborative journey toward success. Get in touch today, and let's bring your project to life!
              </p>
              <button
                className="default-btn bg-[#458BB5] text-white px-6 sm:px-8 py-2 sm:py-3 rounded-md transition transform hover:scale-105 hover:bg-[#34779C] inline-block"
                onClick={handleContactUs}
              >
                <i className="flaticon-right mr-2"></i>Get Started
              </button>
            </div>
          </div>
          <div className="lg:w-1/2 w-full mt-8 lg:mt-0 lg:pl-12 pr-4">
            <div
              className="aos-init aos-animate"
              // data-aos="fade-up"
              // data-aos-duration="800"
              // data-aos-delay="400"
            >
              <img
                alt="banner"
                className="w-3/4 sm:w-2/3 md:w-1/2 lg:w-full h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-xl mx-auto"
                src={GetStartedImg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
