import React, { useEffect, useState } from 'react';
import './Team.css'; // Import the custom CSS
import TeamMembersData from './TeamMembers.json'; // Import the JSON data
import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS CSS for animations
import LocalCEOImage from '../img/Ceoo-img.png'; // Import the local image for CEO
import LocalCOOImage from '../img/COO-img.png'; // Import the local image for COO

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
    setTeamMembers(TeamMembersData);
  }, []);

  return (
    <div className="pt-32 pb-8 bg-white-50" id="Team">
      <div className="container mx-auto text-center">
        <div className="mb-4 flex items-center justify-center">
          <img src="https://tarn-react.envytheme.com/img/star-icon.png" alt="Rotating" className="w-8 h-8 mr-2 rotate-slow" />
          <h2 className="text-[#458BB5]  text-[15px] font-nunito font-semibold">TEAM MEMBERS</h2>
        </div>
        <h3 className="text-[44px] font-nunito font-bold text-212529">Meet the minds behind</h3>
        <p className="text-6b6b84 mt-4 max-w-3xl mx-auto text-[17px] font-nunito">
          Meet our dynamic team of experts dedicated to turning ideas into reality. With a blend of passion and proficiency, we're committed to delivering excellence in every project.
        </p>
        <div className="flex flex-wrap justify-center mt-8 min-h-96">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className={`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4 ${
                index === 0 ? 'lg:ml-4' : index === teamMembers.length - 1 ? 'lg:mr-4' : ''
              }`}
              // data-aos="fade-up"
              // data-aos-delay={`${index * 100}`}
              // data-aos-duration="1000"
            >
              <div className="h-lg bg-white rounded-lg shadow-lg  overflow-hidden relative transition duration-500 ease-in-out hover:scale-105">
                <img
                  className={`w-full h-65 object-cover ${index === 0 ? 'team-image' : ''}`} 
                  src={index === 0 ? LocalCEOImage : member.title === "Chief Operating Officer" ? LocalCOOImage : member.image}
                  alt={member.name}
                />
                <div className="p-4 mb-4">
                  <h4 className="text-[20px] font-nunito font-semibold mb-0 text-212529">{member.name}</h4>
                  <p className={`text-[16px] font-nunito text-[#458BB5]  mb-0`}>{member.title}</p>
                </div>
                <div className="absolute inset-0 flex items-center mt-24  justify-center space-x-4 opacity-0 hover:opacity-100 transition-opacity duration-500 ease-in-out">
                  {member.social.map((social, idx) => (
                    <a
                      target="blank"
                      key={idx}
                      href={social.url}
                      className="text-gray-500 bg-white rounded-full p-2 hover:bg-[#458BB5]  hover:text-white transition-colors duration-300"
                    >
                      <i className={social.icon}></i>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
