import React from "react";
import {
  
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CaseStudyPage from "./components/CaseStudyPage";
import Header from "./components/Header";
import SolutionArea from "./components/SolutionArea";
import Team from "./components/Team";
import TestimonialArea from "./components/TestimonialArea";
import Projects from "./components/Projects";
import Services from "./components/Services";
import ContactUsPage from "./components/ContactUsPage";
import SolutionPage from "./components/SolutionPage";
import GetStarted from "./components/GetStarted";
import BlogPage from "./components/BlogPage";

import PrivacyPage from "./components/PrivacyPage";
function App() {
  console.log("App component rendered");
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
             <meta name="facebook-domain-verification" content="bdcgtbf9n79y8r0ye5fyb1p4cqd6mm" />
              <Header />
              <SolutionArea />
              <Services />
              <Team />
              <Projects />
              {/* <TestimonialArea /> Remove Temporary on Client Demand */}
              <GetStarted/>
              {/* <Footer /> */}
            </>
          }
        />

        <Route
          path="/CaseStudyPage/:projectId"
          element={
            <>
              <CaseStudyPage />
              {/* <Footer /> */}
            </>
          }
        />

        <Route
          path="/ContactUs"
          element={
            <>
              {/* <SolutionPage/> */}
              {/* <PrivacyPage/> */}
              <ContactUsPage />
              {/* <Footer /> */}
            </>
          }
        />

        <Route
          path="/PrivacyPage"
          element={
            <>
              
              <PrivacyPage/>
              
            </>
          }
        />

        <Route
          path="/SolutionPage/:solutionId"
          element={
            <>
              <SolutionPage />
              {/* <Footer /> */}
            </>
          }
        />
        <Route
          path="/BlogPage"
          element={
            <>
              {/* <SolutionPage/> */}
              <BlogPage />
              {/* <Footer /> */}
            </>
          }
        />
      </Routes>
      <Footer />

      
    </BrowserRouter>
  );
}

export default App;
